@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Poppins", sans-serif;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.hero {
  background-image: url("/public/images/hero/hero-bg.png");
  background-repeat: no-repeat;
}
.gain {
  background-image: url("/public/images/gain/gain-bg.png");
  background-repeat: no-repeat;
}

.testimonial__cont {
  background-image: url("/public/images/testimonial/bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #fff;
}

.hero_nav {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.why {
  background-image: url("/public/images/whyjoin/bg.png");
  background-size: cover;
  background-color: #fff;
}


.iframe-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; 
  height: 0;
}
.iframe-container iframe{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}